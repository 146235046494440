import React, {useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import "./style.css";
import { GlobalContext } from "../../global_context";
import ChartSummary from "../../components/ProductDashboard/ChartSummary/ChartSummary";
import ChartData from "../../components/ProductDashboard/ChartData/ChartData";
import ManageVideo from "../../components/ManageVideo/ManageVideo";
import { fetchFilteredDocumentByLevels, getFiltersSetting, getProjectSection } from "../../apis";
import { useParams } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Margin, usePDF } from "react-to-pdf";

// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import SurveyCreatorComponent from "../../components/Survey";
function ProjectDashboard() {
 
  const { auth } =
  useContext(GlobalContext);
  console.log("incoming auth", auth);
  const { id: projectId } = useParams();
  const [section, setSection] = useState([]);

  const [questionnaireJson, setQuestionnaireJson] = useState([]);
  const [levelsDropdown, setLevelsDropdown] = useState();
  const [
    selectedGeneralDetailFilterValues,
    setSelectedGeneralDetailFilterValues,
  ] = useState({});
  const [chartData, setChartData] = useState(null);
  const [answerJson, setAnswerJson] = useState("");
  const [mediaUrl, setMediaUrl] = useState([]);
  const [qV, setQV] = useState([]);
  const [lastSelectedName, setLastSelectedName] = useState("");

  
  const [loading, setLoading] = useState({
    getFilters: false,
    chartData: false,
  });

  const handleLoading = (name, status) => {
    setLoading((pre) => ({
      ...pre,
      [name]: status,
    }))
  }



  useEffect(() => {
    if (projectId) {
      const fetchData = async () => {
        const res = await getProjectSection(projectId);
        if (res.status === 200) {
          setSection(res.data.sections[0]);
          setQuestionnaireJson(
            JSON.parse(res.data.sections[0].questionnaire.questionnaireJSON)
          );
        }
      };
      fetchData(projectId);
    }
    // setRegionModal(true);
  }, [projectId ]);
  console.log("ques questionnaireJson", questionnaireJson);
  console.log("ques answerJson", answerJson);
  useEffect(() => {
    if (projectId) {
      const getDashboardLevelFilter = async (projectId) => {
        try {
         handleLoading("getFilters", true);
          const { status, data } = await getFiltersSetting(projectId);
          if (status === 200 && data?.data) {
            console.log("data?.data", data?.data);
            setLevelsDropdown(data?.data);
          }
         handleLoading("getFilters", false);

        } catch (error) {
         handleLoading("getFilters", false);

          // handleLoading("fetchLoading", false);
        }
      };
      getDashboardLevelFilter(projectId);
    }
  }, [projectId]);

  const handleGeneralDetailFilterDropdownChange = (event, dropdownName, lastDropdown) => {
    debugger
    const { value, id, name } = event.target;
    console.log("id", id, name);
    const find = lastDropdown?.find((cur) => cur?.id == value)?.name;
    if(find){
      setLastSelectedName(find);
    }
    console.log("found", find);


    const res = removeLevelsAfterSelected(
      dropdownName,
      selectedGeneralDetailFilterValues
    );
    setSelectedGeneralDetailFilterValues((prevValues) => ({
      ...res,
      [dropdownName]: value,
    }));
  };

  // Function to remove key-value pairs after the selected level
  const removeLevelsAfterSelected = (selectedLevel, dropdownoptions) => {
    if (dropdownoptions[selectedLevel]) {
      const selectedLevelIndex =
        Object.keys(dropdownoptions).indexOf(selectedLevel);
      const keysToRemove = Object.keys(dropdownoptions).slice(
        selectedLevelIndex + 1
      );
      keysToRemove.forEach((key) => {
        delete dropdownoptions[key];
      });
    }
    return dropdownoptions;
  };

  useEffect(() => {
    console.log(
      "selectedGeneralDetailFilterValues",
      selectedGeneralDetailFilterValues
    );
    if (levelsDropdown?.dropDownFilters) {
      let dropDownFiltersLastLevel = Object.keys(
        levelsDropdown?.dropDownFilters
      )[Object.keys(levelsDropdown?.dropDownFilters).length - 1];
      let selectedGeneralDetailFilterValuesLastLevel = Object.keys(
        selectedGeneralDetailFilterValues
      )[Object.keys(selectedGeneralDetailFilterValues).length - 1];
      console.log(
        "first",
        dropDownFiltersLastLevel,
        "second",
        selectedGeneralDetailFilterValuesLastLevel
      );
      if (
        dropDownFiltersLastLevel === selectedGeneralDetailFilterValuesLastLevel
      ) {
        console.log("call api");

        getFilteredDocumentByLevels();
      }
    }
  }, [selectedGeneralDetailFilterValues]);


  const getFilteredDocumentByLevels = async () => {
    debugger;
    handleLoading("chartData", true);
    let payload = {
      projectId,
      ...selectedGeneralDetailFilterValues,
    };
    const { status, data } = await fetchFilteredDocumentByLevels(payload);

    console.log(
      "fetchFilteredDocumentByLevels",
      status,
      data,
      data[0]?.waveDataTable,
      "otherAttributes",
      data[0]?.otherAttributes[0]?.detail[0]?.value,
      "media urls",
      data[0]?.mediaLinks
    );

    

    // if(status === 200 && data[0]?.otherAttributes[0]?.detail[0]?.value){
    //   setAnswerJson(data[0]?.otherAttributes[0]?.detail[0]?.value)
    //   }else{
    //   setAnswerJson("")
    //   }

      // if(data[0]?.mediaLinks){
      //   setMediaUrl(data[0]?.mediaLinks);
      // }else{
      //   setMediaUrl([]);
      // }


    if (status === 200 && data.length) {

      let isQvAvailable = false;
      let isMediaLinksAvailable = false;

      data[0]?.otherAttributes.forEach((cur) => {

        // if(cur.title === "Answer Json"){
        //   setAnswerJson(cur?.detail[0]?.value)
        // }

        if(cur.title === "mediaLinks"){
          isMediaLinksAvailable = true;
        setMediaUrl(cur?.detail[0]?.value);
        }

        if(cur.title === "qv"){
          isQvAvailable = true;
           setQV(cur?.detail[0]?.value);
        }
      });

      if(!isMediaLinksAvailable){
        setMediaUrl([]);
     }

      if(!isQvAvailable){
        setQV([]);
     }

  



 


      console.log("otherAttributes", data[0]?.otherAttributes[0]?.detail[0]?.value);

      setChartData(data[0]?.waveDataTable);
    handleLoading("chartData", false);
    }else{
      setChartData([])
      handleLoading("chartData", false);
    }
  };



  function beautify(str) {
    str = str.replaceAll("_", " ");
    str = str.replaceAll("&", " & ");
    return str;
  }

  const [chartType, setChartType] = useState("line");
  const [showCharGrid, setShowChartGrid] = useState(false);


  function changeChartRadio(e, name){
    debugger;
   setChartType(name);
  }

  console.log("z",  auth.userName);

  console.log("last level",  Object.values(
    selectedGeneralDetailFilterValues
  )[Object.values(selectedGeneralDetailFilterValues).length - 1], "again=", );

  const { toPDF, targetRef } = usePDF({
    filename: `${lastSelectedName}.pdf`,
    page: { margin: Margin.NONE }
  });

  return (
    <div>
      
      <div ref={targetRef}>
      {/* //--------layout = redirect user to /projects if guesUser is undefined, false, null */}
      {/* //------AnonymousSocialQuestionnaire = gives modal if api response is undefined from webClientApi */}
      {/* <Header /> */}

      <div id="ProjectDashboard">
        <div className="d-flex flex-row justify-content-between">
          <div className="">
            <img src={levelsDropdown?.logo || require("../../assets/logo.png")} width="100" alt="" />
          </div>
          <div className="heading-div text-center">
            <h5 className="text-capitalize heading-1">
              pak suzuki dealer assessment report ( { chartData?.[0]?.waves[chartData?.[0]?.waves?.length - 1]?.date || " - "} ) {" "}
            </h5>
            <h5 className="heading-2">NEW CAR SHOWROOM</h5>
          </div>

          <div className="">
            {/* <img src={require('../../assets/logo.png')} width="100" alt="" /> */}
          </div>
        </div>

        <div className="d-flex flex-wrap justify-content-between mt-3">
          {loading.getFilters && "Loading..."}
          {levelsDropdown?.dropDownFilters
            ? Object.keys(levelsDropdown.dropDownFilters)?.map(
                (dropdown, index) => {
                  if (index === 0) {
                    return (
                      <div key={index} className="mb-1">
                        <label htmlFor="">
                          {levelsDropdown?.levelsJSON?.[index]["name"]}
                        </label>
                        <select
                          style={{ height: "32px", width: "360px" }}
                          className="form-select form-control form-select-sm fontSize_12"
                          onChange={(e) =>
                            handleGeneralDetailFilterDropdownChange(e, dropdown)
                          }
                          value={
                            selectedGeneralDetailFilterValues[dropdown] || ""
                          }
                          aria-label="Default select example"
                        >
                          <option selected>select option</option>
                          {levelsDropdown?.dropDownFilters[dropdown]?.map(
                            (option) => {
                              return (
                                <>
                                {console.log("Premission", option?.Permission)}
                               {(option?.Permission?.includes(auth?.userName) || option?.Permission?.includes(auth?.id?.toString())) &&
                                 // logic
                                 <option key={option?.id} value={option?.id} >
                                 {option?.name}
                               </option>
                              }
                              </>
                              );
                            }
                          )}
                        </select>
                      </div>
                    );
                  } else {
                    const previousDropdownSelectedValue =
                      selectedGeneralDetailFilterValues[
                        Object.keys(levelsDropdown.dropDownFilters)[index - 1]
                      ];
                    return (
                      <div key={index} className="mb-1">
                        <label htmlFor="">
                          {levelsDropdown?.levelsJSON?.[index]["name"]}
                        </label>
                        <select
                          value={
                            selectedGeneralDetailFilterValues[dropdown] || ""
                          }
                          style={{ height: "32px", width: "360px" }}
                          onChange={(e) =>
                            handleGeneralDetailFilterDropdownChange(e, dropdown, levelsDropdown?.dropDownFilters[dropdown][
                              previousDropdownSelectedValue
                            ])
                          }
                          className="form-select form-control form-select-sm fontSize_12"
                          aria-label="Default select example"
                        >
                          <option selected>select option</option>
                          {console.log("previousDropdownSelectedValue", levelsDropdown?.dropDownFilters[dropdown][
                            previousDropdownSelectedValue
                          ]
                          )}
                          {/* <option key={-1} value={-1} disabled={previousDropdownSelectedValue == -1 }>All</option> */}
                          <option key={-1} value={-1} >All</option>
                          {/* Render options based on the selected value of the previous dropdown */}
                          {levelsDropdown?.dropDownFilters[dropdown][
                            previousDropdownSelectedValue
                          ]?.map((option) => {
                            return (
                              <option key={option?.id} value={option?.id} >
                                {option?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    );
                  }
                }
              )
            : ""}
        </div>

        {loading.chartData && <div className="initail_Message">Loading...</div>}

        {/* { <ChartSummary chartData={[]} />} */}
 

        {/* {chartData === null && loading.chartData === false && <div className="initail_Message">Waiting For The User Action</div>} */}

{loading.chartData === false && chartData?.length > 0 ? 
 <>
        {chartData?.length > 0 && <ChartSummary chartData={chartData} />}

         <div className="d-flex align-items-baseline mt-3" >
          <div className="me-2" style={{fontSize: "0.8rem"}}><b>Options:</b></div>
          <div className="me-2"><ManageVideo mediaUrl={mediaUrl} /></div>
          <div className="">
          <div class="form-check form-check-inline">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="line" checked={chartType === 'line'} onChange={(e) => changeChartRadio(e, "line")}/>
  <label class="form-check-label" for="flexRadioDefault1">
    Line Chart
  </label>
</div>
<div class="form-check form-check-inline">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="column" checked={chartType === 'column'} onChange={(e) => changeChartRadio(e, "column")}  />
  <label class="form-check-label" for="flexRadioDefault2">
    Bar Chart
  </label>
</div>
          </div>
          <div className="me-2">
        <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={showCharGrid === true} onChange={(e) => setShowChartGrid(!showCharGrid)} />
  <label class="form-check-label" for="flexCheckChecked">
    Grid
  </label>
</div>
</div>

<div className="me-2">
<a
          onClick={toPDF}
          className="btn-primary btn btn-sm px-1 py-0"
          // href="/"
        >
          Export PDF
        </a>

</div>

         </div>
         </>
: chartData === null && loading.chartData === false ?
<div className="initail_Message">Waiting For The User Action</div>
 :
 <div className="initail_Message">No Data</div>
         }
        

        {/* {levelsDropdown && chartData.length === 0 && (
          <> */}
            {/* <ChartData heading={"Overall Score"} />

             <ChartData heading={"Initail"} /> */}


            {/* <ChartData heading={"Interior, Exterior & Ambiance"} /> */}

            {/* <ChartData heading={"Reception"} />

            <ChartData heading={"Skills Evaluation"} /> */}
          {/* </>
        )} */}


        {loading.chartData === false && chartData?.length > 0 && (
          <>
            {chartData.map((cur) => (
              <>
                <ChartData
                  heading={cur.label}
                  optionDetail={cur.waves}
                  chartType={chartType}
                  showCharGrid={showCharGrid}
                />
              </>
            ))}
          </>
        )}


        {loading.chartData === false && qV?.length !== 0 && 
         <Accordion className="mt-3">
         <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Questionniare</Typography>
          </AccordionSummary>
          <AccordionDetails>
        <table class="table questionare_Table">
        <thead>
          <tr >
            <th className="border" scope="col">Serial&nbsp;No.</th>
            <th className="border" scope="col">Questions</th>
            {/* <th scope="col">Response</th> */}
            <th className="border" scope="col">Available&nbsp;Marks</th>
            <th className="border" scope="col">Achived&nbsp;Marks</th>
            <th className="border" scope="col">Shopper&nbsp;Comments</th>

          </tr>
        </thead>
        <tbody>

          {Object.entries(qV)?.map((cur, index) => (

            <>
             <tr className="border" style={{backgroundColor: "#36508D", color: "white"}}>
            {/* {console.log("cur[0]", cur[0], "cur[1]", cur[1], "Object.entries(qV)", Object.entries(cur[1]) )} */}
            
             <th scope="row"></th>
             <td><b>{cur[0]}</b></td>
             {/* <td></td>  */}
             <td></td>
             <td></td>
             <td></td>
           </tr>

           {Object.entries(cur[1]).map((sub, i) => (
            <tr>
            {/* {console.log("sub", sub, "sub[1].caption", sub[1].caption )} */}
            
             <td className="border">{sub[1]?.serialNo}</td>
             <td className="border">{sub[1]?.caption}</td>
             {/* <td>{}</td> */}
             <td className="border text-center">{sub[1]?.maxValue}</td>
             <td className="border text-center">{sub[1]?.acheivedValue}</td>
             <td className="border">{sub[1]?.comment}</td>
           </tr>
           ))}
           </>

          ))}

     
      
        </tbody>
      </table>
      </AccordionDetails>
      </Accordion> 
        }

{/* //  <Accordion className="mt-3">
//        <AccordionSummary
//           expandIcon={<ExpandMoreIcon />}
//           aria-controls="panel1a-content"
//           id="panel1a-header"
//         >
//           <Typography>Questionniare</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//          {
//           questionnaireJson && answerJson !== "" ? (
//             <SurveyCreatorComponent
//                         isEdit={false}

//                         questionnaireJson={questionnaireJson}
//                       />
//           ): 
//           <div className="text-center">
//             <span style={{fontSize: '14px', marginTop: '20px'}} >No data</span>
//           </div>
//          }
//         </AccordionDetails>
//       </Accordion>  */}
  





      </div>

      </div>
    </div>
  );
}

export default ProjectDashboard;
