const BASE_URL = "";
const LOGIN_USER = "/login";
export const GOOGLE_CLIENT_ID =
  "57099057432-22bt3a99go7s0mjkrnemc51fpofok1k6.apps.googleusercontent.com";
export const PROJECT_LIST_ROUTE = "/projects";
export const apiv11_webClient = "/api/v1/webClient";
export const urlUser_auth = "urlUser_auth";
export const ERROR_TEXT = "Something went wrong";
export const LOGIN_PAGE_ROUTE = "/";
export const QUESTIONNAIRE_RESULT = "questionnaireResult";
export const apiv1_DashboardFiltersSetting = "/api/v1/dashboard/levels"
export const apiv1_Dashboard = "/api/v1/dashboard";
export const apiv1_ProjectSetting = "/api/v1/projectSetting";
export const FACEBOOK_AUTH_ID = "498561998555338";
export const SPECIFIC_USER = "specificUser";
export const FIELD_USER = "fieldUser";
export const ANONYMOUS_USER = "anonymousUser";
export const SOCIAL_LOIGN = "socialLogin";
export const GUEST_USER = "u";
export const TIME_OUT = 5000;
export const BICRUX_URL = "https://www.bicrux.com/";
export const RESPONSE_SAVE_MESSAGE = "Your response has been saved!";
export const apiv1_projectClassificationOneOffSettings =
  "/api/v1/projectClassificationOneOffSettings";
export { BASE_URL, LOGIN_USER };
